import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Columns from "react-bulma-components/lib/components/columns/columns"
import Column from "react-bulma-components/lib/components/columns/components/column"
import Container from "react-bulma-components/lib/components/container/container"
import Content from "react-bulma-components/lib/components/content/content"

import Heading from "react-bulma-components/lib/components/heading/heading"
import Image from "react-bulma-components/lib/components/image/image"
import { getImageUrl } from "takeshape-routing"

import Section from "../generic"
import Styles from "./index.module.scss"


const Contact = props => {
	const query = useStaticQuery(graphql`
		query {
			takeshape {
				getContact {
					image {
						path
					}
					name
					street
					streetNumber
					zipCode
					city
					mail
					phone
					fax
					mobile
				}

				getpartnersList {
					items {
						link
						name
						logo {
							path
						}
					}
				}
			}
		}
	`)

	const {
		getContact,
		getpartnersList
	} = query.takeshape

	const { items: partnerLinks } = getpartnersList
	return (
		<Section
			isDark
			hasSeparatorTop
			hasSeparatorBottom
			{ ...props }>

			<Heading
				textSize={ 3 }
				textAlignment={ "centered" }
				className={ "has-margin-bottom-xl" }
			>
				Kontakt
			</Heading>

			<Container>
				<Columns centered multiline>
					<ContactTile { ...getContact } />

					{ partnerLinks?.length ? (
						<Partners partners={ partnerLinks } />
					) : null }
				</Columns>
			</Container>
		</Section>
	)
}

const ContactTile = ({
	image,
	name,
	street,
	streetNumber,
	zipCode,
	city,
	mail,
	phone,
	fax,
	mobile
}) =>
	<Column
		className={ Styles.tile }
		narrow
	>

		<Columns>

			<Column
				narrow
				paddingless
			>
				<Image
					src={ getImageUrl(image.path) }
					className={ Styles.image }
				/>
			</Column>

			<Column className={ Styles.contentWrapper }>

				<Columns className={ Styles.content }>

					<Column narrow>
						<Heading
							subtitle
							textWeight={ "bold" }
							className={ "has-margin-bottom-xs" }
						>
							Anschrift
						</Heading>

						<Content>
							{ name } <br />
							{ street } { streetNumber } <br />
							{ zipCode } { city }
						</Content>
					</Column>

					<Column narrow>
						<Heading
							subtitle
							textWeight={ "bold" }
							className={ "has-margin-bottom-xs" }
						>
							Kontakt
						</Heading>

						<Content className={ Styles.contactLinksWrapper }>
							{ mail && (
								<ContactEntry
									label={ "E-Mail" }
									value={ mail }
									linkPrefix={ "mailto" }
								/>
							) }

							{ phone && (
								<ContactEntry
									label={ "Fon" }
									value={ phone }
									linkPrefix={ "tel" }
								/>
							) }

							{ fax && (
								<ContactEntry
									label={ "Fax" }
									value={ fax }
									linkPrefix={ "tel" }
								/>
							) }

							{ mobile && (
								<ContactEntry
									label={ "Mobil" }
									value={ mobile }
									linkPrefix={ "tel" }
								/>
							) }
						</Content>
					</Column>
				</Columns>
			</Column>

		</Columns>

	</Column>

const ContactEntry = ({ label, linkPrefix, value }) =>
	<p className={ Styles.entry }>
		<span className={ Styles.label }>{ label }</span>
		<a href={ `${ linkPrefix }:${ value }` }>{ value }</a>
	</p>

const Partners = ({ partners }) => {
	return (
		<Column size={ 12 }>

			<Heading
				textSize={ 4 }
				textAlignment={ "centered" }
			>
				Partner
			</Heading>

			<Columns
				centered
				className={ "is-mobile" }
			>

				{ partners.map(({ logo, link }) =>
					<Column
						className={ Styles.partner }
						key={ link }
						narrow
						renderAs={ "a" }
						href={ link }
						rel={ "noreferrer" }
						target={ "_blank" }
					>

						<img
							src={ getImageUrl(logo.path) }
							style={ {
								height: "6rem"
							} }
							alt={ "" }
						/>

					</Column>
				) }

			</Columns>
		</Column>
	)
}

export default Contact
